.tabComponent {
    align-items: center;
    color: color(FG);
    background-color: color(BG-darker);
    float: left;
    width: 100%;
}
.path {
    padding: 0.4em 0.625em;
    color: color(comment);
    background: color(BG-lighter);
    -webkit-box-shadow: 0 0.5em 0.375em -0.375em black;
	   -moz-box-shadow: 0 0.5em 0.375em -0.375em black;
            box-shadow: 0 0.5em 0.375em -0.375em black,
                        -0.5em 0.3125em 0.375em -0.375em black,
                        0.5em 0.3125em 0.375em -0.375em black;
    display: flex;
    overflow: hidden;
    &.moved{
        padding-top: 0em;
    }
}
.path_text{
    margin-right: 0.5em;
    min-width: fit-content;
    line-height: 1em;
    &:hover{
        color: color(FG);
        cursor: pointer;
    }
}
.xBttn {
    color: color(FG);
    &.shadow {
        visibility: hidden;
        color: color(comment);
    }
}
.tab{
    background-color: transparent;
    color: color(FG);
    border: none;
    outline: none;

    font-family: Consolas, 'Courier New', monospace;
    cursor: pointer;

    width: fit-content;
    background: color(BG-lighter);
    border-top: 0.0625em solid color(d-purple);
    display: flex;
    border-right: 0.0625em solid color(BG-darker);
    &.shadow {
        background-color: color(BG-dark);
        color: color(comment);
        border-top: 0.0625em solid transparent;

    }
    &:hover .xBttn{
        visibility: visible;
    }
}
.tabLabel{
    margin: auto;
}
.tabBar{
    display: flex;
    overflow: auto;
    overflow-y: hidden;

    &:hover{
        &::-webkit-scrollbar-thumb {
            background: color(hover-trns);
        }
    }
    scrollbar-width: thin;
    scrollbar-color: color(hover-trns) color(BG-light)  !important;
    
    &::-webkit-scrollbar {
        height: 0.4em;
    }
    &::-webkit-scrollbar-track {
        background: color(BG-light);
    }
    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: color(hover);
    }
}
.tabBttn{
    background-color: transparent;
    color: color(FG);
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0;
    height: 1em;
    font-family: Consolas, 'Courier New', monospace;
    cursor: pointer;
}
.link{
    color:color(FG);
    text-decoration: none;
    padding: 0.625em;
    display: flex;
}
.linkX{
    display: flex;
    margin: auto 0.3125em auto 0;
    &.dis{
        pointer-events: none;
    }
}
.rightOF{
    position: absolute;
    right: 0;
    top: 2.6em;
    color: color(comment);
    &:hover{
        color: color(FG);
    }
    &.large{
        top: 3.3em;
        color: color(FG);
    }
}
.leftOF{
    position: absolute;
    left: 0;
    top: 2.6em;
    color: color(comment);
    &:hover{
        color: color(FG);
    }
    &.large{
        top: 3.3em;
        color: color(FG);
    }
}
.exclamation{
    transform: rotate(8deg);
    font-size: 1em!important;
    color: color(d-purple);
    margin-right: 0.3em;
}