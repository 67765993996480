.window {
    position: absolute;
    height: 100%;
    width: 100%;
    top:1vh;
    right: 0;
    left:0;
    margin: 0 auto;
    width: 65%;
    min-width: 35em;
    max-width: 60em;
    height: 97vh;
    min-height: 20.625em;
    //max-height: 28.125em;
    background-color: color(BG-light);
    border-bottom: 0.0625em solid color(d-purple);
    display: block;
}
.mobile{
    height: 99.6%;
        width: 100%;
        top:0;
        right: 0;
        position: absolute;
    background-color: color(BG-light);
    border-bottom: 0.25em solid color(d-purple);
    display: block;
    font-size: 40px;
}