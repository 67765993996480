.default{
    height: calc(100% - 3.7125em);
    width: 100%;
    padding-top: 3.7125em;
}
.dLogo{
   // margin: 3.125em 0;
   // text-align: center;
}
.asciiCanvas{
    margin: 3em auto 1em;
    width: fit-content;
    
}
.dText{
    margin: auto;
    text-align: center;
}
.dTextWrapper{
    margin: 0 1em;
}
.dRed{
    color: color(d-red);
    text-decoration: none;
    &.dlink{
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.dPurple{
    color: color(d-purple);
    text-decoration: none;
    &.dlink{
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.dBlue{
    color: color(d-blue);
    text-decoration: none;
    &.dlink{
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.dYellow{
    color: color(d-yellow);
    text-decoration: none;
    &.dlink{
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.dPink{
    color: color(d-pink);
    text-decoration: none;
    &.dlink{
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}