@import './colors.scss';
@import './components/index.scss';

body {
  font-family: Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: color(d-black);
  font-size: 16px;
  color: color(FG);
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}



button::-moz-focus-inner {
  border: 0;
}

a::-moz-focus-inner {
  border: 0;
}
