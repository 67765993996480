.wBttns{
    color: color(FG);
    background: color(BG-light);
    width: 100%;
    height: fit-content;
    float: left;
    display: flex;
    font-size: 0.86em;
}
.wIcons{
    cursor: pointer;
    padding: 0.2em 0.3em;

    &:hover{
        background-color: color(hover);
    }
    &.red {
        &:hover{
        background-color: color(d-red);
        }
    }
}
.wTitle{
margin: auto;
}
.wIconsWrapper{
    display: flex;
}

.trans{
    max-width: 6.95em;
    flex: 1;
    
}